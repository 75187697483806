<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div class="card-title">
              <h3 class="card-label">Bajarilgan ishlar ro'yxati</h3>
            </div>
          </div>

          <div class="card-body">
            <table id="customers">
              <tr>
                <th>Наименование услуг</th>
                <th>Kg</th>
                <th>m3</th>
                <th>Дата</th>
                <th>Ед. изм</th>
                <th>Кол-во</th>
                <th>Цена</th>
                <th>НДС 15%</th>
                <th>Стоимость услуг с учетом НДС</th>
              </tr>
              <tr v-for="item in getData" :key="item.id">
                <td>
                  Услуги перевозки
                  <span
                    v-for="(citiesName, index) in item.zayavka_direction_info"
                    :key="citiesName.id"
                  >
                    {{ citiesName.city_name.name }}
                    <span
                      v-if="index !== item.zayavka_direction_info.length - 1"
                    >
                      <span>—</span>
                    </span>
                  </span>
                </td>
                <td>
                  {{ item.weight }}
                </td>
                <td>
                  {{ item.volume }}
                </td>
                <td>
                  {{ item.start_date_info }}
                </td>
                <td>шт</td>
                <td>1</td>
                <td>
                  {{ item.price.toLocaleString('zh-Zh') }}
                </td>
                <td>
                  {{ item.nds_summa.toLocaleString('zh-Zh') }}
                </td>
                <td>
                  {{ item.price_with_nds.toLocaleString('zh-Zh') }}
                </td>
              </tr>
              <tr>
                <td colspan="4"></td>
                <td>Итого:</td>
                <td>{{ getData.length }}</td>
                <td>{{ totalPrice }}</td>
                <td>{{ totalPriceNds }}</td>
                <td>
                  {{ totalPriceWithNds.toLocaleString('zh-ZH') }}
                </td>
              </tr>
              <tr>
                <td class="totalprice" colspan="9">
                  Всего к оплате с учетом НДС: {{ totalPriceWithNdsWords }}
                </td>
              </tr>
            </table>

            <table class="tablefooter">
              <tr>
                <td colspan="4">
                  Работу сдал____________________________________
                  <p>М.П</p>
                </td>

                <td colspan="5">
                  Принял________________________________________
                  <p>М.П</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numberToWordsRu from 'number-to-words-ru'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {}
  },
  // Action
  created() {
    this.$store.dispatch(
      'getClientPageFacturesCompletedWorks',
      this.$route.params.id
    )
  },
  computed: {
    totalPriceWithNdsWords() {
      return numberToWordsRu.convert(this.totalPriceWithNds, {
        currency: {
          currencyNameCases: ['сум', 'сум', 'сум'],
          fractionalPartNameCases: ['тийин', 'тийин', 'тийин'],
          currencyNounGender: {
            integer: 0,
            fractionalPart: 0
          }
        }
      })
    },
    getData() {
      return this.$store.state.clientStore.clientPageFacturesCompletedWorks
    },
    totalPrice() {
      return this.getData
        .reduce((acc, cur) => acc + Number(cur.price), 0)
        .toLocaleString('zh-Zh')
    },
    totalPriceNds() {
      return this.getData
        .reduce((acc, cur) => acc + Number(cur.nds_summa), 0)
        .toLocaleString('zh-Zh')
    },
    totalPriceWithNds() {
      return this.getData.reduce(
        (acc, cur) => acc + Number(cur.price_with_nds),
        0
      )
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Bajarilgan ishlar' }])
  }
}
// Style
</script>

<style scoped>
.tablefooter {
  margin-top: 2rem;
}
.totalprice {
  font-weight: bold;
}
#customers {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4c81af;
  color: white;
}
</style>
